<template>
  <div class="con-item">
    <div class="container">
      <div class="news-menu">
        <a-card :title="title" :bordered="false">
          <a-menu
            :selectedKeys="defaultTitle"
            theme="light"
            mode="inline"
            @select="changeMenu"
          >
            <template v-for="item in titleList">
              <a-menu-item v-if="!item.childrenList" :key="item.id">
                <i></i>
                <span>{{ item.themename }}</span>
              </a-menu-item>
              <Sub-menu v-else :key="item.id" :menu-info="item" />
            </template>
          </a-menu>
        </a-card>
      </div>
      <div class="list">
        <a-list :grid="{ column: 1 }" :data-source="dataList">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-card :bordered="false">
              <template slot="title">
                <i></i>
                <a @click="godown(item)" :title="item.title">{{
                  item.title
                }}</a>
              </template>
              <!-- <template slot="extra">{{ item.pubdate }}</template> -->
            </a-card>
          </a-list-item>
        </a-list>
        <a-pagination
          show-quick-jumper
          v-show="total > 10"
          :default-current="current"
          :total="total"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "./hearMenu.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "NewsList",
  components: { SubMenu },
  props: {
    titleList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultTitle: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    titleName: {
      type: String,
      default: "",
    },
    routerName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelValue: [],
    };
  },
  created() {},
  methods: {
    //查看新闻详情
    godown(item) {
      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/api/resource/download?doi=" +
        item.doi +
        "&downloadName=" +
        item.fileName;
      window.location.href = url;
    },
    onChange(number) {
      this.$emit("changePage", number);
    },
    changeMenu(item) {
      this.$emit("getChangeId", item.key);
    },
  },
};
</script>
<style lang="less">
@import "../news/NewsList.less";
@import "../QuillEditor/quill-editor.less";
</style>
