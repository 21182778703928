<template>
  <div class="news-list">
    <div class="page-title">
      <div class="container">
        <div class="con-title con-title-white">
          <span>下载专区</span>
        </div>
      </div>
    </div>
    <div>
      <div class="container">
        <news-list
          :titleList="titleList"
          :dataList="dataList"
          :defaultTitle="defaultTitle"
          :total="total"
          :current="current"
          :content="content"
          :titleName="titleName"
          routerName="ContactDetails"
          title="下载专区"
          @getChangeId="getChangeId"
          @changePage="changePage"
        ></news-list>
      </div>
    </div>
  </div>
</template>

<script>
import newsList from "@/views/components/newsListdown.vue";
import * as api from "@/services/download.js";
export default {
  name: "BrandIndexDetails",
  components: { newsList },
  data() {
    return {
      titleList: [],
      dataList: [],
      defaultTitle: [],
      total: 0,
      current: 1,
      content: "",
      titleName:''
    };
  },
  created() {
    //查询新闻栏目列表
    this.getAllLevelTheme();
  },
  methods: {
    changePage(number) {
      this.current = number;
      this.getNoteList(this.defaultTitle[0]);
    },
    getChangeId(id) {
      this.defaultTitle = [id];
      this.current = 1;
      this.getNoteList(id);
    },
    getAllLevelTheme() {
      this.titleList = [
        {themename:'文件下载',id:'0'},
        {themename:'视频下载',id:'10'},
        {themename:'音频下载',id:'11'},
      ]
      this.defaultTitle = ['0']
      this.getNoteList(this.defaultTitle[0])
    },
    getNoteList(id) {
      let obj = {
        resType: id,
        pageNumber: this.current,
        pageSize: 10,
      };
      api.getList(obj).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.data.list;
          this.total = Number(res.data.data.count);
          this.content=""
          this.titleName=""
        }
      });
    },
    onChange(pageNumber) {
      console.log("Page: ", pageNumber);
    },
  },
};
</script>
<style lang="less">
@import "../journal/journalIndex.less";
</style>
