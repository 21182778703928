import {
    request,
    METHOD
} from '@/utils/request'
import {
    addurl
} from '@/utils/util'
import {
    baseURL
} from '@/config/proxyconfig'

const requesturl = baseURL + '/api/resource/'
//前台资源下载专用接口
export async function download(params) {
    return request(addurl('download', requesturl) + '?doi=' + params.doi + '&downloadName=' + params.downloadName,
        METHOD.GET)
}
//
// 作者详情页面-->根据作者id展示图书列表和作者详细信息
export async function getList(params) {
    return request(addurl('getList', requesturl) + '/' + params.pageNumber + '/' + params.pageSize + '?resType=' + params.resType,
        METHOD.GET)
}


export default {
    getList,
}